import pull from 'lodash/pull';
import { Enums } from '@flightscope/baseball-stats';
import DTIndexes from '@/enums/DataTableColumnIndexes';
import { concatAndDeDuplicate } from '../utils/helpers';

class DataTableColumnsProvider {
  constructor() {
    this.defineKnownColumns();

    this.defaultColumnsIndexes = [
      DTIndexes.No,
      DTIndexes.Video,
      DTIndexes.PitchTime,
      DTIndexes.PitchDate,
      DTIndexes.PitchPitcher,
      DTIndexes.PitchHandedness,
      DTIndexes.PitchPitcherTeam,
      DTIndexes.HitBatter,
      DTIndexes.HitSwingHandedness,
      DTIndexes.HitBatterTeam,
      DTIndexes.PitchMechanics,
      DTIndexes.PitchClassification,
      DTIndexes.PitchCall,
      DTIndexes.PlateAppearanceResult,
      DTIndexes.PitchResult,
      DTIndexes.PitchSpeed,
      DTIndexes.PitchLaunchV,
      DTIndexes.PitchLaunchH,
      DTIndexes.PitchSpin,
      DTIndexes.PitchSpinDirection,
      DTIndexes.PitchSpinTiltR,
      DTIndexes.PitchReleaseHeightR,
      DTIndexes.PitchReleaseSideInvR,
      DTIndexes.PitchExtension,
      DTIndexes.PitchMlbBreakV,
      DTIndexes.PitchMlbBreakVInd,
      DTIndexes.PitchMlbBreakH,
      DTIndexes.PitchHomePlateHeightR,
      DTIndexes.PitchHomePlateSideR,
      DTIndexes.PitchApproachSpeed,
      DTIndexes.PitchApproachV,
      DTIndexes.PitchApproachH,
      DTIndexes.PitchFlightTime,
      DTIndexes.HitExitSpeed,
      DTIndexes.HitLaunchV,
      DTIndexes.HitLaunchH,
      // DTIndexes.HitSpin,
      DTIndexes.HitCarryDistance,
      DTIndexes.HitFlightTime,
      DTIndexes.PitchPFXX,
      DTIndexes.PitchPFXZ,
      DTIndexes.PitchX0,
      DTIndexes.PitchY0,
      DTIndexes.PitchZ0,
      DTIndexes.PitchVX0,
      DTIndexes.PitchVY0,
      DTIndexes.PitchVZ0,
      DTIndexes.PitchAX,
      DTIndexes.PitchAY,
      DTIndexes.PitchAZ,
      DTIndexes.PitchPX,
      DTIndexes.PitchPZ,
      DTIndexes.PitchStrikeZonePositionR,
      DTIndexes.PitchCountR,
      DTIndexes.PitchPlateAppearanceR,
      DTIndexes.HitBattedBallTypeR,

      DTIndexes.ScoringBeforePlayInning,
      DTIndexes.ScoringBeforePlayInningIsTop,
      // DTIndexes.ScoringBeforePlayRunnersOn,
      // DTIndexes.ScoringBeforePlayBalls,
      // DTIndexes.ScoringBeforePlayStrikes,
      // DTIndexes.ScoringBeforePlayOuts,
      // DTIndexes.ScoringAfterPlayRuns,
      // DTIndexes.ScoringAfterPlayErrors,

      DTIndexes.PitchPitcherLeagueId,
      DTIndexes.PitchPitcherExternalId,
      DTIndexes.HitBatterLeagueId,
      DTIndexes.HitBatterExternalId,
    ];

    // Indexes list of common type columns
    this.commonColumnsIndexes = [DTIndexes.No, DTIndexes.Video];

    // Indexes list of hit type columns
    this.hitColumnsIndexes = [
      DTIndexes.HitBatter,
      DTIndexes.HitBatterID,
      DTIndexes.HitBatterLeagueId,
      DTIndexes.HitBatterExternalId,
      DTIndexes.HitSwingHandedness,
      DTIndexes.HitBatterTeam,
      DTIndexes.HitExitSpeed,
      DTIndexes.HitLaunchV,
      DTIndexes.HitLaunchH,
      // DTIndexes.HitSpin,
      DTIndexes.HitCarryDistance,
      DTIndexes.HitTrackDistance,
      DTIndexes.HitFlightTime,
      DTIndexes.HitHitPoint,
      DTIndexes.HitLandingPoint,
      DTIndexes.HitPolyX,
      DTIndexes.HitPolyY,
      DTIndexes.HitPolyZ,
      DTIndexes.HitBattedBallTypeR,
      DTIndexes.HitLandingSideR,
      DTIndexes.HitLandingDistanceR,
    ];

    // Indexes list of pitch type columns
    this.pitchColumnsIndexes = [
      DTIndexes.PitchTime,
      DTIndexes.PitchUTCTime,
      DTIndexes.PitchDate,
      DTIndexes.PitchUTCDate,
      DTIndexes.PitchPitcher,
      DTIndexes.PitchPitcherID,
      DTIndexes.PitchPitcherLeagueId,
      DTIndexes.PitchPitcherExternalId,
      DTIndexes.PitchHandedness,
      DTIndexes.PitchPitcherTeam,
      DTIndexes.PitchMechanics,
      DTIndexes.PitchClassification,
      DTIndexes.PitchResult,
      DTIndexes.PitchSpeed,
      DTIndexes.PitchLaunchV,
      DTIndexes.PitchLaunchH,
      DTIndexes.PitchSpin,
      DTIndexes.PitchSpinDirection,
      DTIndexes.PitchSpinTiltR,
      DTIndexes.PitchReleaseHeightR,
      DTIndexes.PitchReleaseSideInvR,
      DTIndexes.PitchExtension,
      DTIndexes.PitchMlbBreakV,
      DTIndexes.PitchMlbBreakVInd,
      DTIndexes.PitchMlbBreakH,
      DTIndexes.PitchHomePlateHeightR,
      DTIndexes.PitchHomePlateSideR,
      DTIndexes.PitchApproachSpeed,
      DTIndexes.PitchApproachV,
      DTIndexes.PitchApproachH,
      DTIndexes.PitchFlightTime,
      DTIndexes.PitchPFXX,
      DTIndexes.PitchPFXZ,
      DTIndexes.PitchX0,
      DTIndexes.PitchY0,
      DTIndexes.PitchZ0,
      DTIndexes.PitchVX0,
      DTIndexes.PitchVY0,
      DTIndexes.PitchVZ0,
      DTIndexes.PitchAX,
      DTIndexes.PitchAY,
      DTIndexes.PitchAZ,
      DTIndexes.PitchGuid,
      DTIndexes.PitchPX,
      DTIndexes.PitchPZ,
      DTIndexes.PitchStrikeZoneFront,
      DTIndexes.PitchStrikeZoneTop,
      DTIndexes.PitchStrikeZoneBottom,
      DTIndexes.PitchMlbSpinTiltHours,
      DTIndexes.PitchMlbSpinTiltMinutes,
      DTIndexes.PitchPolyX,
      DTIndexes.PitchPolyY,
      DTIndexes.PitchPolyZ,
      DTIndexes.PitchStrikeZonePositionR,
      DTIndexes.PitchCountR,
      DTIndexes.PitchPlateAppearanceR,
    ];

    // scoring related params goes here (BBH)
    this.scoreColumnsIndexes = [
      DTIndexes.ScoringBeforePlayInning,
      DTIndexes.ScoringBeforePlayInningIsTop,
      DTIndexes.ScoringBeforePlayRunnersOn,
      DTIndexes.ScoringBeforePlayBalls,
      DTIndexes.ScoringBeforePlayStrikes,
      DTIndexes.ScoringBeforePlayOuts,
      DTIndexes.ScoringAfterPlayRuns,
      DTIndexes.ScoringAfterPlayErrors,
    ];

    // new apps specific scoring params goes here
    this.newScoreColumnsIndexes = [
      DTIndexes.PitchCall,
      DTIndexes.PlateAppearanceResult,
    ];

    // parameters which are available only when radar has been used during the session recording
    this.radarColumnsIndexes = [
      // for hit:
      DTIndexes.HitExitSpeed,
      DTIndexes.HitLaunchH,
      DTIndexes.HitLaunchV,
      DTIndexes.HitFlightTime,
      DTIndexes.HitCarryDistance,
      DTIndexes.HitTrackDistance,
      DTIndexes.HitPolyX,
      DTIndexes.HitPolyY,
      DTIndexes.HitPolyZ,
      DTIndexes.HitLandingPoint,
      DTIndexes.HitLandingSideR,
      DTIndexes.HitLandingDistanceR,
      DTIndexes.HitHitPoint,
      // for pitch:
      DTIndexes.PitchSpeed,
      DTIndexes.PitchLaunchH,
      DTIndexes.PitchLaunchV,
      DTIndexes.PitchSpin,
      DTIndexes.PitchFlightTime,
      DTIndexes.PitchReleaseHeightR,
      DTIndexes.PitchReleaseSideInvR,
      DTIndexes.PitchHomePlateHeightR,
      DTIndexes.PitchHomePlateSideR,
      DTIndexes.PitchExtension,
      DTIndexes.PitchPolyX,
      DTIndexes.PitchPolyY,
      DTIndexes.PitchPolyZ,
      DTIndexes.PitchSpin,
      DTIndexes.PitchMlbSpinTiltHours,
      DTIndexes.PitchMlbSpinTiltMinutes,
      DTIndexes.PitchMlbBreakV,
      DTIndexes.PitchMlbBreakVInd,
      DTIndexes.PitchMlbBreakH,
      DTIndexes.PitchPFXX,
      DTIndexes.PitchPFXZ,
      DTIndexes.PitchX0,
      DTIndexes.PitchY0,
      DTIndexes.PitchZ0,
      DTIndexes.PitchVX0,
      DTIndexes.PitchVY0,
      DTIndexes.PitchVZ0,
      DTIndexes.PitchAX,
      DTIndexes.PitchAY,
      DTIndexes.PitchAZ,
      DTIndexes.PitchPX,
      DTIndexes.PitchPZ,
      DTIndexes.PitchSpinDirection,
      DTIndexes.PitchSpinTiltR,
      DTIndexes.PitchApproachSpeed,
      DTIndexes.PitchApproachV,
      DTIndexes.PitchApproachH,
      DTIndexes.PitchStrikeZoneFront,
      DTIndexes.PitchStrikeZoneTop,
      DTIndexes.PitchStrikeZoneBottom,
      DTIndexes.PitchStrikeZonePositionR,
    ];

    this.indexesOfV2ExcludedColumns = [
      DTIndexes.PitchResult,
    ];

    this.taggingColumnsIndexes = [
      DTIndexes.PitchMechanics,
      DTIndexes.PitchClassification,
      DTIndexes.PitchResult,
    ];

    if (
      process.env.VUE_APP_SPORT_TYPE === Enums.SportType.Softball.key
      || process.env.VUE_APP_SPORT_TYPE !== Enums.SportType.Baseball.key
    ) {
      pull(this.defaultColumnsIndexes, DTIndexes.PitchMechanics);
      pull(this.pitchColumnsIndexes, DTIndexes.PitchMechanics);
    }

    this.allColumns = this.getAllColumnsIndexes();
  }

  defineKnownColumns() {
    let resultData = Enums.ResultData;
    let pitchData = Enums.PitchData;
    let hitData = Enums.HitData;

    this.pitchParam = 'Pitch';
    this.hitParam = 'Hit';
    this.resultParam = 'result';

    // order defined as https://docs.google.com/spreadsheets/d/1l0nF5rqTBP7eFHrfYZkifps_qQYJJNaUti6aFGAViNI/edit?ts=5e8af2da#gid=0
    this.knownColumns = [];
    this.knownColumns[DTIndexes.No] = {
      text: '№',
      align: 'center',
      exportName: 'No',
      type: 'STRING',
      preventExport: true,
      value: 'SeqNo',
      class: 'sticky',
    };
    this.knownColumns[DTIndexes.Video] = {
      text: 'Video',
      preventExport: true,
      sortable: false,
      value: 'Media',
      type: 'OBJECT',
    };
    this.knownColumns[DTIndexes.PitchTime] = {
      text: 'Time',
      value: `${this.pitchParam}.${resultData.CreateDate.key}`,
      path: [this.pitchParam, resultData.CreateDate.key],
      type: 'DATETIME',
      format: 'HH:mm:ss',
      utc: false,
    };
    this.knownColumns[DTIndexes.PitchUTCTime] = {
      text: 'UTC Time',
      value: `${this.pitchParam}.${resultData.CreateDate.key}`,
      path: [this.pitchParam, resultData.CreateDate.key],
      type: 'DATETIME',
      format: 'HH:mm:ss',
      utc: true,
      exportName: DataTableColumnsProvider.getExportName('UTC Time'),
    };
    this.knownColumns[DTIndexes.PitchDate] = {
      text: 'Date',
      value: `${this.pitchParam}.${resultData.CreateDate.key}`,
      path: [this.pitchParam, resultData.CreateDate.key],
      type: 'DATETIME',
      format: 'date',
      utc: false,
    };
    this.knownColumns[DTIndexes.PitchUTCDate] = {
      text: 'UTC Date',
      value: `${this.pitchParam}.${resultData.CreateDate.key}`,
      path: [this.pitchParam, resultData.CreateDate.key],
      type: 'DATETIME',
      format: 'date',
      utc: true,
      exportName: DataTableColumnsProvider.getExportName('UTC Date'),
      key: DTIndexes.PitchUTCDate,
    };
    this.knownColumns[DTIndexes.PitchPitcher] = {
      text: 'Pitcher',
      value: 'PitcherName',
      path: ['PitcherName'],
      width: 150,
    };
    this.knownColumns[DTIndexes.PitchPitcherID] = {
      text: 'Pitcher ID',
      value: 'Pitcher.id',
      type: 'STRING',
      path: ['Pitcher', 'id'],
      exportName: DataTableColumnsProvider.getExportName('Pitcher ID'),
    };
    this.knownColumns[DTIndexes.PitchPitcherLeagueId] = {
      text: 'Pitcher League ID',
      value: 'Pitcher.leagueID',
      type: 'STRING',
      path: ['Pitcher', 'leagueID'],
      exportName: DataTableColumnsProvider.getExportName('Pitcher League ID'),
    };
    this.knownColumns[DTIndexes.PitchPitcherExternalId] = {
      text: 'Pitcher External ID',
      value: 'Pitcher.externalID',
      type: 'STRING',
      path: ['Pitcher', 'externalID'],
      exportName: DataTableColumnsProvider.getExportName('Pitcher External ID'),
      isTooLong: true,
    };
    this.knownColumns[DTIndexes.PitchPitcherIDType] = {
      text: 'Pitcher ID Type',
      value: 'Pitcher.Type',
      type: 'STRING',
      path: ['Pitcher', 'Type'],
      exportName: DataTableColumnsProvider.getExportName('Pitcher ID Type'),
    };
    this.knownColumns[DTIndexes.PitchHandedness] = this.mapProperty(this.pitchParam, pitchData.Handedness);
    this.knownColumns[DTIndexes.PitchPitcherTeam] = {
      text: 'Pitcher Team',
      value: 'Pitcher.team.displayName',
      type: 'STRING',
      path: ['Pitcher', 'team', 'displayName'],
      exportName: DataTableColumnsProvider.getExportName('Pitcher Team'),
      width: 120,
    };

    this.knownColumns[DTIndexes.HitBatter] = {
      text: 'Batter',
      value: 'BatterName',
      path: ['BatterName'],
      width: 150,
    };
    this.knownColumns[DTIndexes.HitBatterID] = {
      text: 'Batter ID',
      value: 'Batter.id',
      type: 'STRING',
      path: ['Batter', 'id'],
      exportName: DataTableColumnsProvider.getExportName('Batter ID'),
    };
    this.knownColumns[DTIndexes.HitBatterLeagueId] = {
      text: 'Batter League ID',
      value: 'Batter.leagueID',
      type: 'STRING',
      path: ['Batter', 'leagueID'],
      exportName: DataTableColumnsProvider.getExportName('Batter League ID'),
    };
    this.knownColumns[DTIndexes.HitBatterExternalId] = {
      text: 'Batter External ID',
      value: 'Batter.externalID',
      type: 'STRING',
      path: ['Batter', 'externalID'],
      exportName: DataTableColumnsProvider.getExportName('Batter External ID'),
      isTooLong: true,
    };
    this.knownColumns[DTIndexes.HitBatterIDType] = {
      text: 'Batter ID Type',
      value: 'Batter.Type',
      type: 'STRING',
      path: ['Batter', 'Type'],
      exportName: DataTableColumnsProvider.getExportName('Batter ID Type'),
    };
    this.knownColumns[DTIndexes.HitSwingHandedness] = this.mapProperty(this.pitchParam, pitchData.SwingHandedness);
    this.knownColumns[DTIndexes.HitBatterTeam] = {
      text: 'Batter Team',
      value: 'Batter.team.displayName',
      type: 'STRING',
      path: ['Batter', 'team', 'displayName'],
      exportName: DataTableColumnsProvider.getExportName('Batter Team'),
      width: 120,
    };

    // tagging
    if (process.env.VUE_APP_SPORT_TYPE === Enums.SportType.Baseball.key) {
      this.knownColumns[DTIndexes.PitchMechanics] = this.mapProperty(this.pitchParam, pitchData.Mechanics);
    }
    this.knownColumns[DTIndexes.PitchClassification] = this.mapProperty(this.pitchParam, pitchData.Classification);
    this.knownColumns[DTIndexes.PitchResult] = this.mapProperty(this.pitchParam, pitchData.Result);

    // pitch params
    this.knownColumns[DTIndexes.PitchSpeed] = this.mapProperty(this.pitchParam, pitchData.Speed);
    this.knownColumns[DTIndexes.PitchLaunchV] = this.mapProperty(this.pitchParam, pitchData.LaunchV);
    this.knownColumns[DTIndexes.PitchLaunchH] = this.mapProperty(this.pitchParam, pitchData.LaunchH);
    this.knownColumns[DTIndexes.PitchSpin] = this.mapProperty(this.pitchParam, pitchData.Spin, { precision: 0 });
    this.knownColumns[DTIndexes.PitchSpinDirection] = this.mapProperty(this.pitchParam, pitchData.SpinDirection);
    this.knownColumns[DTIndexes.PitchSpinTiltR] = this.mapProperty(
      this.pitchParam,
      Enums.PitchReprocessed.PitchSpinTiltR
    );

    this.knownColumns[DTIndexes.PitchReleaseHeightR] = this.mapProperty(
      this.pitchParam,
      Enums.PitchReprocessed.ReleaseHeightR
    );
    this.knownColumns[DTIndexes.PitchReleaseSideR] = this.mapProperty(
      this.pitchParam,
      Enums.PitchReprocessed.ReleaseSideR
    );
    this.knownColumns[DTIndexes.PitchReleaseSideInvR] = this.mapProperty(
      this.pitchParam,
      Enums.PitchReprocessed.ReleaseSideInvR
    );

    this.knownColumns[DTIndexes.PitchExtension] = {
      ...this.mapProperty(this.pitchParam, {...pitchData.Extension}),
      precision: 1,
    };

    this.knownColumns[DTIndexes.PitchMlbBreakV] = this.mapProperty(this.pitchParam, pitchData.MlbBreakV);
    this.knownColumns[DTIndexes.PitchMlbBreakVInd] = this.mapProperty(this.pitchParam, pitchData.MlbBreakVInd);
    this.knownColumns[DTIndexes.PitchMlbBreakH] = this.mapProperty(this.pitchParam, pitchData.MlbBreakH);

    this.knownColumns[DTIndexes.PitchHomePlateHeightR] = this.mapProperty(
      this.pitchParam,
      Enums.PitchReprocessed.HomePlateHeightR
    );
    this.knownColumns[DTIndexes.PitchHomePlateSideR] = this.mapProperty(
      this.pitchParam,
      Enums.PitchReprocessed.HomePlateSideR
    );

    this.knownColumns[DTIndexes.PitchApproachSpeed] = this.mapProperty(this.pitchParam, pitchData.ApproachSpeed);
    this.knownColumns[DTIndexes.PitchApproachV] = this.mapProperty(
      this.pitchParam,
      pitchData.ApproachV,
      { width: 120 }
    );
    this.knownColumns[DTIndexes.PitchApproachH] = this.mapProperty(
      this.pitchParam,
      pitchData.ApproachH,
      { width: 120 }
    );

    this.knownColumns[DTIndexes.PitchFlightTime] = this.mapProperty(this.pitchParam, pitchData.FlightTime);

    // hit params
    this.knownColumns[DTIndexes.HitExitSpeed] = this.mapProperty(this.hitParam, hitData.ExitSpeed);
    this.knownColumns[DTIndexes.HitLaunchV] = this.mapProperty(this.hitParam, hitData.LaunchV);
    this.knownColumns[DTIndexes.HitLaunchH] = this.mapProperty(this.hitParam, hitData.LaunchH);
    // this.knownColumns[DTIndexes.HitSpin] = this.mapProperty(this.hitParam, hitData.Spin, { precision: 0 });
    this.knownColumns[DTIndexes.HitCarryDistance] = this.mapProperty(this.hitParam, hitData.CarryDistance);
    this.knownColumns[DTIndexes.HitTrackDistance] = this.mapProperty(this.hitParam, hitData.TrackDistance);
    this.knownColumns[DTIndexes.HitFlightTime] = this.mapProperty(this.hitParam, hitData.FlightTime);

    // pitchF/x params
    this.knownColumns[DTIndexes.PitchPFXX] = this.mapProperty(this.pitchParam, pitchData.PFXX);
    this.knownColumns[DTIndexes.PitchPFXZ] = this.mapProperty(this.pitchParam, pitchData.PFXZ);
    this.knownColumns[DTIndexes.PitchX0] = this.mapProperty(this.pitchParam, pitchData.X0);
    this.knownColumns[DTIndexes.PitchY0] = this.mapProperty(this.pitchParam, pitchData.Y0);
    this.knownColumns[DTIndexes.PitchZ0] = this.mapProperty(this.pitchParam, pitchData.Z0);
    this.knownColumns[DTIndexes.PitchVX0] = this.mapProperty(this.pitchParam, pitchData.VX0);
    this.knownColumns[DTIndexes.PitchVY0] = this.mapProperty(this.pitchParam, pitchData.VY0);
    this.knownColumns[DTIndexes.PitchVZ0] = this.mapProperty(this.pitchParam, pitchData.VZ0);
    this.knownColumns[DTIndexes.PitchAX] = this.mapProperty(this.pitchParam, pitchData.AX);
    this.knownColumns[DTIndexes.PitchAY] = this.mapProperty(this.pitchParam, pitchData.AY);
    this.knownColumns[DTIndexes.PitchAZ] = this.mapProperty(this.pitchParam, pitchData.AZ);

    this.knownColumns[DTIndexes.PitchGuid] = this.mapProperty(this.pitchParam, pitchData.Guid);

    // and rest of pitch f/x .. ask Tom...
    this.knownColumns[DTIndexes.PitchPX] = this.mapProperty(this.pitchParam, pitchData.PX);
    this.knownColumns[DTIndexes.PitchPZ] = this.mapProperty(this.pitchParam, pitchData.PZ);

    // optional stuff goes here
    this.knownColumns[DTIndexes.PitchStrikeZoneFront] = this.mapProperty(this.pitchParam, pitchData.StrikeZoneFront);
    this.knownColumns[DTIndexes.PitchStrikeZoneTop] = this.mapProperty(this.pitchParam, pitchData.StrikeZoneTop);
    this.knownColumns[DTIndexes.PitchStrikeZoneBottom] = this.mapProperty(this.pitchParam, pitchData.StrikeZoneBottom);
    this.knownColumns[DTIndexes.PitchMlbSpinTiltHours] = this.mapProperty(this.pitchParam, pitchData.MlbSpinTiltHours, {
      precision: 0,
    });
    this.knownColumns[DTIndexes.PitchMlbSpinTiltMinutes] = this.mapProperty(
      this.pitchParam,
      pitchData.MlbSpinTiltMinutes,
      { precision: 0 }
    );
    this.knownColumns[DTIndexes.HitHitPoint] = this.mapProperty(this.hitParam, hitData.HitPoint);
    this.knownColumns[DTIndexes.HitLandingPoint] = this.mapProperty(this.hitParam, hitData.LandingPoint);

    this.knownColumns[DTIndexes.PitchPolyX] = this.mapProperty(this.pitchParam, pitchData.PolyX);
    this.knownColumns[DTIndexes.PitchPolyY] = this.mapProperty(this.pitchParam, pitchData.PolyY);
    this.knownColumns[DTIndexes.PitchPolyZ] = this.mapProperty(this.pitchParam, pitchData.PolyZ);
    this.knownColumns[DTIndexes.HitPolyX] = this.mapProperty(this.hitParam, hitData.PolyX);
    this.knownColumns[DTIndexes.HitPolyY] = this.mapProperty(this.hitParam, hitData.PolyY);
    this.knownColumns[DTIndexes.HitPolyZ] = this.mapProperty(this.hitParam, hitData.PolyZ);

    // reprocessed stuff goes here
    this.knownColumns[DTIndexes.PitchStrikeZonePositionR] = this.mapProperty(
      this.pitchParam,
      Enums.PitchReprocessed.StrikeZonePositionR
    );
    this.knownColumns[DTIndexes.PitchCountR] = this.mapProperty(this.pitchParam, Enums.PitchReprocessed.PitchCountR, {
      precision: 0,
    });
    this.knownColumns[DTIndexes.PitchPlateAppearanceR] = this.mapProperty(
      this.pitchParam,
      Enums.PitchReprocessed.PlateAppearanceR,
      { precision: 0}
    );
    this.knownColumns[DTIndexes.HitBattedBallTypeR] = this.mapProperty(
      this.hitParam,
      Enums.HitReprocessed.BattedBallTypeR
    );

    // new stuff goes here
    this.knownColumns[DTIndexes.HitLandingSideR] = this.mapProperty(this.hitParam, Enums.HitReprocessed.LandingSideR);
    this.knownColumns[DTIndexes.HitLandingDistanceR] = this.mapProperty(
      this.hitParam,
      Enums.HitReprocessed.LandingDistanceR
    );

    // scoring data
    this.knownColumns[DTIndexes.ScoringBeforePlayInning] = this.mapProperty(
      this.pitchParam,
      Enums.PitchData.BeforePlayInning,
      { precision: 0 }
    );
    this.knownColumns[DTIndexes.ScoringBeforePlayInningIsTop] = this.mapProperty(
      this.pitchParam,
      Enums.PitchData.BeforePlayInningIsTop,
      { precision: 0 }
    );
    this.knownColumns[DTIndexes.ScoringBeforePlayRunnersOn] = this.mapProperty(
      this.pitchParam,
      Enums.PitchData.BeforePlayRunnersOn
    );
    this.knownColumns[DTIndexes.ScoringBeforePlayBalls] = this.mapProperty(
      this.pitchParam,
      Enums.PitchData.BeforePlayBalls,
      { precision: 0 }
    );
    this.knownColumns[DTIndexes.ScoringBeforePlayStrikes] = this.mapProperty(
      this.pitchParam,
      Enums.PitchData.BeforePlayStrikes,
      { precision: 0 }
    );
    this.knownColumns[DTIndexes.ScoringBeforePlayOuts] = this.mapProperty(
      this.pitchParam,
      Enums.PitchData.BeforePlayOuts,
      { precision: 0 }
    );
    this.knownColumns[DTIndexes.ScoringAfterPlayRuns] = this.mapProperty(
      this.pitchParam,
      Enums.PitchData.AfterPlayRuns,
      { precision: 0 }
    );
    this.knownColumns[DTIndexes.ScoringAfterPlayErrors] = this.mapProperty(
      this.pitchParam,
      Enums.PitchData.AfterPlayErrors,
      { precision: 0 }
    );

    // new scoring data
    this.knownColumns[DTIndexes.PitchCall] = {
      ...this.mapProperty(this.pitchParam, Enums.ScoringData.PitchCall),
      width: 85
  };
    this.knownColumns[DTIndexes.PlateAppearanceResult] = this.mapProperty(
      this.pitchParam,
      Enums.ScoringData.PlateAppearanceResult,
      { width: 120 }
    );
  }

  getIndexesOfDefaultColumns() {
    return this.defaultColumnsIndexes;
  }

  getIndexesOfRadarColumns() {
    return this.radarColumnsIndexes;
  }

  getIndexesOfV2ExcludedColumns() {
    return this.indexesOfV2ExcludedColumns;
  }

  getIndexesOfTaggingColumns() {
    return this.taggingColumnsIndexes;
  }

  getIndexesOfNewScoreColumns() {
    return this.newScoreColumnsIndexes;
  }

  getAllColumns() {
    return this.getColumns(...this.allColumns);
  }

  // todo - refactor it, it doesn't make sense
  getAllColumnsCount() {
    return this.allColumns.length;
  }

  getAllColumnsIndexes() {
    // todo - order by knownColumns index..., see DataTableColumnsWrapper.getIndexesOfAvailableColumns
    return concatAndDeDuplicate(
      this.commonColumnsIndexes,
      this.pitchColumnsIndexes,
      this.hitColumnsIndexes,
      this.scoreColumnsIndexes,
      this.newScoreColumnsIndexes,
    );
  }

  getColumns(...indexes) {
    let columns = [];
    for (let i = 0; i < indexes.length; i++) {
      const index = indexes[i];
      columns.push(this.getColumn(index));
    }

    return columns;
  }

  getColumn(index) {
    return { ...this.knownColumns[index], key: index };
  }

  mapProperty(type, property, baseOn, overrideBy) {
    if (typeof baseOn !== 'object') {
      baseOn = {};
    }

    if (typeof overrideBy !== 'object') {
      overrideBy = {};
    }

    let propertyPath = [];
    if (type !== this.resultParam) {
      propertyPath.push(type);
    }

    propertyPath = propertyPath.concat(Enums.ResultData.getDataPath(property.key));
    const tempProperty = {
      text: property.name,
      property,
      path: propertyPath,
      value: propertyPath.join('.'),
      type: property.type,
      exportType: DataTableColumnsProvider.getExportType(property),
      exportName: DataTableColumnsProvider.getExportName(property.name),
      conversionType: DataTableColumnsProvider.getConversionType(property),
    };

    if (property.enum) {
      tempProperty.type = 'ENUM';
      tempProperty.nameFromEnum = property.enum;
      if (property.enumDefault) {
        tempProperty.DefaultIfEmpty = property.enumDefault;
      }
    }

    if (property.description) {
      // todo - display as a tooltip on the table
      tempProperty.description = property.description;
    }

    let overridden = Object.assign(tempProperty, overrideBy);
    return Object.assign(baseOn, overridden);
  }

  static getExportType(property) {
    return (property.type ?? '').replace('_RL', '').replace('_FTIN', '');
  }

  static getExportName(humanReadableName) {
    const words = humanReadableName.replace('(', '').replace(')', '').split(' ');

    // switch H/V as requested
    const horizontalSignPosition = words.indexOf('H');
    const verticalSignPosition = words.indexOf('V');

    if (horizontalSignPosition > 0) {
      delete words[horizontalSignPosition];
      words.splice(1, 0, 'H');
    }

    if (verticalSignPosition > 0) {
      delete words[verticalSignPosition];
      words.splice(1, 0, 'V');
    }

    return words.filter((n) => n).join('_');
  }

  static getConversionType(property) {
    switch (property.type) {
      case 'STRING':
        return undefined;
      default:
        return property.type;
    }
  }
}

export default new DataTableColumnsProvider();

export { DataTableColumnsProvider };
